<template>
    <div class="pipeline-toggler">
        <div class="select-option" style="min-width: 200px">
            <select v-model="sortBy" @change="doSorting()" style="width: auto;">
                <option value="">{{ $t('Custom Sort') }}</option>
                <option v-for="(val, key) in sortingParam" :value="key">{{ $t(val) }}</option>
            </select>
        </div>
        <router-link :to="{ name : 'job.candidates', params: { jobId } }" class="toggler-button d-block d-md-none custom-move-candidate-button">
            <div class="icon"><i class="eicon e-users"></i></div>
        </router-link>
        <router-link exact-path v-for="tab in pipelineTabs"
            class="toggler-button"
            :to="{ name : tab.name }"
            @click.native="togglePipelineTab(tab.id)"
            :class="togglePipelineActiveClass(tab.id)"
            :key="tab.id"
        >
            <div class="icon"><i class="eicon" :class="tab.icon"></i></div>
        </router-link>
    </div>
</template>

<script>

import {PIPELINE_TABS} from "../../../constants/enums";
import client from "../../../app/api/Client";
import {mapActions, mapState} from "vuex";
import {PIPELINE_CANDIDATE_SORTING} from '../../../extra/constants';
import moment from "moment";
import {UPDATE_PAGE_STATE, SORTING_PIPELINE_CANDIDATE} from "../../../constants/action-type";

export default {
    name: "PipelineToggler",
    data() {
        return {
            pipelineTabs: [
                {
                    id: PIPELINE_TABS.THUMB,
                    name: 'job.pipeline',
                    title: 'Thumbnail',
                    icon: 'e-thumbnail',
                },
                {
                    id: PIPELINE_TABS.BOARD,
                    name: 'job.pipeline.board',
                    title: 'Board',
                    icon: 'e-trello-alt',
                }
            ],
            sortBy: "",
        }
    },
    computed: {
        ...mapState(['pageState']),
        jobId() {
            return this.$route.params.jobId;
        },
        sortingParam() {
            if(!this.pageState.ai_score_show) {
                return _.filter(PIPELINE_CANDIDATE_SORTING, (value, key) => {
                    return key !== 'SORT_BY_TOTAL_AI_SCORE';
                });
            }
            return PIPELINE_CANDIDATE_SORTING;
        }
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE, SORTING_PIPELINE_CANDIDATE]),

        async togglePipelineTab(id) {
            try {
                let {data} = await client().post(`/job/${this.$route.params.jobId}/pipeline/update-view-mode`, {
                    tab_id: id
                });
            } catch (error) {
            }
        },
        togglePipelineActiveClass(id) {
            if(PIPELINE_TABS.THUMB == id && this.pageState.defaultBoard == true) {
                return 'toggle-board-active-tab';
            } else if(PIPELINE_TABS.BOARD == id && this.pageState.defaultBoard == false) {
                return 'toggle-board-active-tab';
            }
        },
        doSorting() {

            this[UPDATE_PAGE_STATE]({
                sortBy: this.sortBy
            });

            this[SORTING_PIPELINE_CANDIDATE]();
        },
    }
}
</script>

<style scoped>
    .toggle-board-active-tab {
        background: #597dfc !important;
        color: #fff !important;
    }

    .pipeline-toggler {
        z-index: 99;
    }
    @media all and (max-width: 767px) {
        .pipeline-toggler {
            margin-left: auto !important;
        }
    }

    @media all and (max-width: 499.98px) {
        .pipeline-toggler {
            margin-bottom: 0px !important;
        }
    }

    .custom-move-candidate-button {
        padding: 10px;
    }

    .select-option {
        height: 42px !important;
    }

    .select-option select{
        height: 40px !important;
    }
</style>
